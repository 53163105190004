<template>
  <div>
    <div v-if="screenType === 'desktop'" class="custom-margin-bottom-container">
      <bread-crumb
          library="Animation Library"
          :selected-category-title="selectedCategoryTitle"
          :selected-sub-category-title="selectedSubCategoryTitle"
          :current-page="currentPage"
          @changeBreadCrumbLibrary="selectedCategoryTitle = null; selectedSubCategoryTitle = null;currentPage = 'Animation Library'"
          @changeBreadCrumbMain="selectedCategoryTitle = null;selectedSubCategoryTitle = null;currentPage = selectedCategoryTitle"
      ></bread-crumb>
      <div class="custom-margin-container">
        <v-row v-if="selectedCategoryTitle == null">
          <v-col @click="loadSubCategories(category)" sm="12" md="4"
                 v-for="category in categories" :key="category.id">
            <category v-if="category.color === 'background: linear-gradient(to right, #fdd49d 0%, #ffbe66 100%);'"
                      class="mainYellowCategory" :style="category.color" :category="category">
            </category>
            <category
                v-if="category.color ==='background: linear-gradient(to right, #8ddcbc 0%, #26bf81 100%);'"
                class="mainGreenCategory" :style="category.color" :category="category">
            </category>
            <category v-if="category.color === 'background: linear-gradient(to right, #fcaa7d 0%, #ff8640 100%);'"
                      class="mainRedCategory" :style="category.color" :category="category">
            </category>
            <category v-if="category.color === 'background: linear-gradient(to top, #c657a0 0%, #f099d2 100%0);'"
                      class="mainPurpleCategory" :style="category.color" :category="category">
            </category>
          </v-col>
          <v-col sm="12">
            <div class="text-center">
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="selectedSubCategoryTitle == null" class="custom-margin-x">
          <v-col @click="loadAnimations(subCategory)" cols="3"
                 v-for="subCategory in subCategories" :key="subCategory.id">
            <category v-if="subCategory.color === 'background: linear-gradient(to right, #fdd49d 0%, #ffbe66 100%);'"
                      class="pa-7 mainYellowCategory" :category="subCategory"
                      style="width: 182px!important;text-align: left!important;"
                      :subCategory="true">
            </category>
            <category v-if="subCategory.color === 'background: linear-gradient(to right, #8ddcbc 0%, #26bf81 100%);'"
                      class="pa-7 mainGreenCategory" :category="subCategory"
                      :subCategory="true" style="width: 182px!important;text-align: left!important;">
            </category>
            <category v-if="subCategory.color === 'background: linear-gradient(to right, #fcaa7d 0%, #ff8640 100%);'"
                      class="pa-7 mainRedCategory" :category="subCategory" :subCategory="true"
                      style="width: 182px!important;text-align: left!important;">
            </category>
            <category v-if="subCategory.color === 'background: linear-gradient(to top, #c657a0 0%, #f099d2 100%0);'"
                      class="pa-7 mainPurpleCategory" :category="subCategory" :subCategory="true"
                      style="width: 182px!important;text-align: left!important;">
            </category>
          </v-col>
          <v-col sm="12">
            <div class="text-center">
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col v-for="animation in animations" :key="'animation'+animation.id" cols="3">
            <product-card :product="animation"></product-card>
          </v-col>
          <v-col sm="12">
            <div class="text-center">
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-row class="breadcrumb-container-mobile">
        <v-col>
          <bread-crumb
              library="Animation Library"
              :selected-category-title="selectedCategoryTitle"
              :selected-sub-category-title="selectedSubCategoryTitle"
              :current-page="currentPage"
              @changeBreadCrumbLibrary="selectedCategoryTitle = null; selectedSubCategoryTitle = null;currentPage = 'Animation Library'"
              @changeBreadCrumbMain="selectedCategoryTitle = null;selectedSubCategoryTitle = null;currentPage = selectedCategoryTitle"
          ></bread-crumb>
        </v-col>
      </v-row>
      <div style="margin-top: 12vh!important;">
        <v-row v-if="selectedCategoryTitle == null" class="mt-16 mb-16">
          <v-col @click="loadSubCategories(category)" cols="12"
                 class="px-12"
                 v-for="category in categories" :key="category.id">
            <category v-if="category.color === 'background: linear-gradient(to right, #fdd49d 0%, #ffbe66 100%);'"
                      class="mainYellowCategoryMobile" :style="category.color" :category="category">
            </category>
            <category
                v-if="category.color ==='background: linear-gradient(to right, #8ddcbc 0%, #26bf81 100%);'"
                class="mainGreenCategoryMobile" :style="category.color" :category="category">
            </category>
            <category v-if="category.color === 'background: linear-gradient(to right, #fcaa7d 0%, #ff8640 100%);'"
                      class="mainRedCategoryMobile" :style="category.color" :category="category">
            </category>
            <category v-if="category.color === 'background: linear-gradient(to top, #c657a0 0%, #f099d2 100%0);'"
                      class="mainPurpleCategoryMobile" :style="category.color" :category="category">
            </category>
          </v-col>
          <v-col sm="12">
            <div class="text-center">
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="selectedSubCategoryTitle == null" class="custom-margin-x mb-16 mt-16">
          <v-col @click="loadAnimations(subCategory)" cols="12"
                 v-for="subCategory in subCategories" :key="subCategory.id">
            <category v-if="subCategory.color === 'background: linear-gradient(to right, #fdd49d 0%, #ffbe66 100%);'"
                      class="pa-7 mainYellowCategory" :category="subCategory"
                      style="width: 100%!important;text-align: left!important;"
                      :subCategory="true">
            </category>
            <category v-if="subCategory.color === 'background: linear-gradient(to right, #8ddcbc 0%, #26bf81 100%);'"
                      class="pa-7 mainGreenCategory" :category="subCategory"
                      :subCategory="true" style="width: 100%!important;text-align: left!important;">
            </category>
            <category v-if="subCategory.color === 'background: linear-gradient(to right, #fcaa7d 0%, #ff8640 100%);'"
                      class="pa-7 mainRedCategory" :category="subCategory" :subCategory="true"
                      style="width: 100%!important;text-align: left!important;">
            </category>
            <category v-if="subCategory.color === 'background: linear-gradient(to top, #c657a0 0%, #f099d2 100%0);'"
                      class="pa-7 mainPurpleCategory" :category="subCategory" :subCategory="true"
                      style="width: 100%!important;text-align: left!important;">
            </category>
          </v-col>
          <v-col sm="12">
            <div class="text-center">
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="mt-16">
          <v-col class="px-8" v-for="animation in animations" :key="'animation'+animation.id" cols="12">
            <product-card :product="animation"></product-card>
          </v-col>
          <v-col sm="12">
            <div class="text-center">
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import Category from "@/components/Category";
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: 'animations',
  components: {BreadCrumb, Category, ProductCard},
  data() {
    return {
      screenType: null,
      currentPage: 'Animation Library',
      animations: {},
      categories: {},
      subCategories: {},
      selectedCategoryTitle: null,
      selectedCategoryId: null,
      selectedSubCategoryTitle: null,
      selectedSubCategoryId: null,
    }
  },
  mounted() {
    this.getScreenType()
    if (this.$route.params.type) {
      if (this.$route.params.type === 'child') {
        this.selectedCategoryTitle = this.$route.params.selectedCategoryTitle
        this.selectedSubCategoryTitle = this.$route.params.selectedSubCategoryTitle
        this.loadAnimations(this.$route.params.category)
      }
      if (this.$route.params.type === 'parent') {
        this.selectedCategoryTitle = this.$route.params.selectedCategoryTitle
        this.loadSubCategories(this.$route.params.category)
      }
    }

    if (this.$route.query.childCategory && this.$route.query.category) {
      this.ChainedLoad(this.$route.query.category, this.$route.query.childCategory)
    } else if (this.$route.query.category) {
      this.ChainedLoad(this.$route.query.category)
    } else {
      this.loadCategories()
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    loadCategories() {
      window.axios.get('api/categories')
          .then((res) => {
            this.categories = res.data.data
          })
    },
    loadSubCategories(category) {
      this.currentPage = category.title
      this.selectedCategoryTitle = category.title
      this.selectedCategoryId = category.id
      window.axios.post('api/categories/child', {
        parentId: [category.id]
      })
          .then((res) => {
            this.subCategories = res.data.data
          })
    },
    loadAnimations(category) {
      this.currentPage = category.title
      window.axios.get('api/animations/index?byCategory=' + category.id).then((res) => {
        this.animations = res.data.data
        console.log(this.animations)
      })
      this.selectedSubCategoryTitle = category.title
      this.selectedSubCategoryId = category.id
    },
    ChainedLoad(categoryId, subCategoryId = null) {
      window.axios.get('api/categories')
          .then((res) => {
            this.categories = res.data.data

            Object.values(this.categories).forEach((el) => {
              if (el.id == categoryId) {
                this.selectedCategoryId = el.id
                this.selectedCategoryTitle = el.title
              }
            })

            window.axios.post('api/categories/child', {
              parentId: [categoryId]
            })
                .then((res) => {
                  this.subCategories = res.data.data

                  if (subCategoryId) {
                    Object.values(this.subCategories).forEach((el) => {
                      if (el.id == subCategoryId) {
                        this.loadAnimations(el)
                      }
                    })
                  }
                })
          })
    }
  }
}
</script>
<style scoped>

.breadcrumb-container-mobile {
  position: absolute;
  z-index: 10;
  width: 104%;
  left: 0;
  top: 80px;
  background-color: #f6f6f9 !important;
}

.mainYellowCategory {
  width: 24vw !important;
  color: #b06908 !important;
  border-radius: 15px;
  box-shadow: 0 15px 35px 0 rgba(255, 198, 119, 0.4) !important;
  background-image: linear-gradient(to top, #ffbe66, #ffdeb2) !important;
}

.mainYellowCategoryMobile {
  width: 100vw !important;
  color: #b06908 !important;
  border-radius: 15px;
  box-shadow: 0 15px 35px 0 rgba(255, 198, 119, 0.4) !important;
  background-image: linear-gradient(to top, #ffbe66, #ffdeb2) !important;
}

.mainGreenCategory {
  width: 24vw !important;
  color: #098a63;
  border-radius: 15px;
  box-shadow: 0 11.3px 26.4px 0 rgba(78, 199, 149, 0.41) !important;
  background-image: linear-gradient(to top, #26bf81, #add9c7) !important;
}

.mainGreenCategoryMobile {
  width: 100vw !important;
  color: #098a63;
  border-radius: 15px;
  box-shadow: 0 11.3px 26.4px 0 rgba(78, 199, 149, 0.41) !important;
  background-image: linear-gradient(to top, #26bf81, #add9c7) !important;
}

.mainRedCategory {
  width: 24vw !important;
  color: #bf5519;
  border-radius: 15px;
  box-shadow: 0 11.3px 26.4px 0 rgba(255, 160, 105, 0.4) !important;
  background-image: linear-gradient(to top, #ff8640, #ffc6a6) !important;
}


.mainRedCategoryMobile {
  width: 100vw !important;
  color: #bf5519;
  border-radius: 15px;
  box-shadow: 0 11.3px 26.4px 0 rgba(255, 160, 105, 0.4) !important;
  background-image: linear-gradient(to top, #ff8640, #ffc6a6) !important;
}

.mainPurpleCategory {
  width: 24vw !important;
  color: #852263;
  border-radius: 15px;
  box-shadow: 0 11.3px 26.4px 0 rgba(209, 104, 173, 0.4) !important;
  background-image: linear-gradient(to top, #c657a0, #f099d2) !important;
}

.mainPurpleCategoryMobile {
  width: 100vw !important;
  color: #852263;
  border-radius: 15px;
  box-shadow: 0 11.3px 26.4px 0 rgba(209, 104, 173, 0.4) !important;
  background-image: linear-gradient(to top, #c657a0, #f099d2) !important;
}


.page-dash {
  font-family: Blinker-Bold;
  font-size: 64px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 1;
  line-height: normal;
  letter-spacing: normal;
  transform: rotateZ(20deg);
  color: #ffbe66;
}

.page-title {
  font-family: Blinker-Bold;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 1;
  line-height: normal;
  letter-spacing: normal;
  color: #ffbe66;
}

.breadcrumb {
  font-size: 14px;
  color: #848e99;
}

.breadcrumb-current {
  font-size: 14px;
  color: #39bf88;
}

.custom-line {
  font-size: 20px !important;
  margin-top: 10px !important;
  border-color: #ffbe66;
  border-left: 1px !important;
  margin-right: 20px !important;
  height: 48px !important;
  background: #ffbe66;
  transform: rotateZ(40deg) !important;
  max-width: 50px !important;
}

.custom-margin-left-main {
  margin-left: 6vw !important;
}

.custom-margin-container {
  margin-left: 8vw !important;
  margin-right: 8vw !important;
}

.custom-margin-bottom-container {
  margin-bottom: 200px !important;
}

.custom-margin-x {
  margin-left: 13vw;
  margin-right: 13vw;
}
</style>